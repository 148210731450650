<template>
  <v-container
    fluid
  >
    <v-layout
      align-center
    >
      <v-flex
        xs12
        sm6
        md6
      >
        <h1>My Lands</h1>

        <br>
        <h3>
          Support:
          <a href="mailto:christoph.metz@cmgm.de">christoph.metz@cmgm.de</a>.
        </h3>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'AppIndex',
};
</script>

<style lang="scss" scoped>
</style>
