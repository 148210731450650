<template>
  <v-layout row>
    <v-col>
      <v-layout v-for="(item, index) in inputVal" :key="item._id" row>
        <v-col>
          <my-select-field v-model="item.user._id" :items="users" item-text="desc" item-value="_id"
            validation-rule="mongoId" label="Besitzer" :required="true" />
        </v-col>
        <v-col>
          <my-text-field v-model="item.zaehler" validation-rule="zaehler" label="Zähler" :required="true" />
        </v-col>
        <v-col>
          <my-text-field v-model="item.nenner" validation-rule="nenner" label="Nenner" :required="true" />
        </v-col>
        <v-col>
          <v-btn @click="removeOwner(index)"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
      </v-layout>
      <v-btn color="raised primary" @click="addOwner">
        ADD
      </v-btn>
    </v-col>
  </v-layout>

</template>

<script>
import MyTextField from '@/components/Generic/MyTextField';
import MySelectField from '@/components/Generic/MySelectField';

import * as UsersService from "@/service/Users";


export default {
  components: {
    MyTextField,
    MySelectField,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      besitzer: [],
      users: [],
    }
  },
  async created() {
    const res = await UsersService.get(['_id', 'username', 'name', 'surname']);
    this.users = res.map(user => {
      return {
        _id: user._id,
        desc: `${user.surname}, ${user.name}`,
      };
    })
  },
  methods: {
    addOwner() {
      this.inputVal.push({
        zaehler: '',
        nenner: '',
        user: {
          _id: '',
        },
      });
    },
    removeOwner(index) {
      this.inputVal.splice(index, 1);
    }
  }
};
</script>
