<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      v-show="isAuthenticated"
      app
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $store.getters.username }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ $store.getters.team }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list>
        <v-list-item link to="/wfs/parcels/view">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-map">
          <template v-slot:activator>
            <v-list-item-title>Lands</v-list-item-title>
          </template>

          <v-list-item link to="/wfs/parcels/retrieve">
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Retrieve</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/wfs/parcels/view">
            <v-list-item-icon>
              <v-icon>mdi-map-search</v-icon>
            </v-list-item-icon>
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>
         
        </v-list-group>


        <v-list-group
          no-action
          prepend-icon="mdi-cog"
          v-show="showSettingsMenu"
        >
          <template v-slot:activator>
            <v-list-item-title>Administration</v-list-item-title>
          </template>

          <v-list-item link to="/teams/retrieve">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/users/retrieve">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider />

      <v-list>
        <v-list-item link href="mailto:christoph.metz@cmgm.de">
          <v-list-item-icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/logout">
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app text tile dense color="black" dark>
      <v-app-bar-nav-icon
        v-show="isAuthenticated"
        @click.stop="drawer = !drawer"
      />
      
      <v-icon>mdi-map-search</v-icon>
      <v-toolbar-title style="cursor: pointer" @click="$router.push('/wfs/parcels/view')">
MyLands</v-toolbar-title>

      <v-spacer />

      
      <!-- navigation -->
      <v-btn v-show="isAuthenticated" icon title="Logout" to="/logout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
      <v-btn v-show="!isAuthenticated" icon title="Help" href="mailto:christoph.metz@cmgm.de" target="_blank">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      
    </v-app-bar>

    <!-- global snackbar for info, error, warn, success messages -->
    <snack-bar ref="snackbar" />

    <!-- global confirmation dialog -->
    <confirm-dialog ref="confirm" />

    <!-- content -->
    <v-main>
      <!-- :key="$route.fullPath" to reinit same components on
        different usage (e.g. create/edit) -->
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import SnackBar from "@/components/Generic/SnackBar";
import ConfirmDialog from "@/components/Generic/ConfirmDialog";
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  name: "App",
  components: {
    SnackBar,
    ConfirmDialog,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    showLandsMenu() {
      return this.$ability.can("manage", "Land");
    },
    showSettingsMenu() {
      return (
        this.$ability.can("manage", "User") &&
        this.$ability.can("manage", "Team")
      );
    },
  },
  mounted() {
    document.title = "MyLands";
    this.$root.$snackbar = this.$refs.snackbar;
    this.$root.$confirm = this.$refs.confirm.open;
  },
  async created() {
    /**
     * global axios default req auth and res error handling
     */
    axios.interceptors.request.use(
      (config) => {
        const { token } = this.$store.getters;
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        let msg = "General server error!";

        if (err.response) {
          if (
            err.response &&
            err.response.status === 401 &&
            // eslint-disable-next-line no-underscore-dangle
            err.config &&
            !err.config.__isRetryRequest
          ) {
            msg = "Authorization failed!";
            this.drawer = false;
            if (this.$store.getters.expired === true) {
              this.$store.dispatch(AUTH_LOGOUT);
              this.$root.$snackbar.error(
                "JWT has been expired, please login again!"
              );
              this.$router.push("/local-login");
              return Promise.reject(err);
            }
          }

          if (err.response.data.error) {
            // global app error handling
            msg = err.response.data.error;
          }
        } else if (err.request) {
          msg = err.toString();
        }

        this.$root.$snackbar.error(msg);

        return Promise.reject(err);
      }
    );
  },
};
</script>

<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";
html {
  overflow-y: initial;
}
.table-actions {
  text-align: right;
}
</style>
