/* eslint-disable no-restricted-syntax */
import { extend } from 'vee-validate';
import rules from '../../../shared/validation/rules';

const getValidationRule = (field) => {
  extend(field, (value, args) => {
    const fieldRules = rules[field].rules;
    const firstFailedRule = fieldRules.find(rule => rule[0](value, ...args) === false);

    if (!firstFailedRule) {
      return true;
    }

    let message = '';
    switch (typeof firstFailedRule[1]) {
      case 'string':
        // eslint-disable-next-line prefer-destructuring
        message = firstFailedRule[1];
        break;
      case 'function':
        message = firstFailedRule[1](value, ...args);
        break;
      default:
        message = `Validation failed for "${field}"!`;
    }

    return message;
  });
};

const getHint = field => rules[field].hint || '';

export {
  getValidationRule,
  getHint,
};
