<template>
  <my-select-field
    v-model="inputVal"
    :items="teams"
    item-text="name"
    item-value="_id"
    validation-rule="mongoId"
    label="Team"
    :required="required"
  />
</template>

<script>
import MySelectField from '@/components/Generic/MySelectField';
import * as TeamsService from '@/service/Teams';

export default {
  components: {
    MySelectField,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    teams: [],
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    async fetchTeams() {
      try {
        const teams = await TeamsService.get(['_id', 'name']);
        // eslint-disable-next-line max-len, no-nested-ternary
        this.teams = teams.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
      } catch (e) { /**/ }
    },
  },
};
</script>
