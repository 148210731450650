<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10">
        <span class="display-1">Teams</span>
        <DataTable
          ref="datatable"
          :headers="headers"
          api-base-path="/api/v1/teams"
          initial-sort-by="name"
          :initial-descending="false"
          :initial-rows-per-page="10"
        >
          <template slot="items" slot-scope="props">
            <tbody>
              <tr v-for="item in props.items" :key="item._id">
                <td>{{ item.name }}</td>
                <td class="table-actions">
                  <TableActions
                    :item="item"
                    :info-button-fn="viewDetails"
                    :action-button-items="actionButtonItems"
                  />
                </td>
              </tr>
            </tbody>
          </template>

          <!-- datatable buttons -->
          <template v-slot:buttons>
            <v-btn to="/teams/create">
              <v-icon left> mdi-plus-circle-outline </v-icon>CREATE</v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>

    <!-- user details dialog -->
    <TeamDetailsDialog v-model="teamDetails" :item="teamDetailsTeam" />
  </v-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import * as TeamService from "@/service/Teams";
import DataTable from "@/components/Generic/DataTable";
import TableActions from "@/components/Generic/TableActions";
import TeamDetailsDialog from "@/components/TeamDetailsDialog";

export default {
  name: "TeamRetrieve",
  components: {
    DataTable,
    TableActions,
    TeamDetailsDialog,
  },
  data() {
    return {
      teamDetails: false,
      teamDetailsTeam: {},
      headers: [
        {
          value: "name",
          text: "Name",
          sortable: true,
          align: "left",
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false,
          align: "end",
        },
      ],
      actionButtonItems: [
        {
          label: "Edit",
          icon: "mdi-pencil",
          click: (item) =>
            this.$router.push({
              name: "TeamEdit",
              params: { editId: item._id },
            }),
          condition: this.isEditTeamAllowed,
        },
        {
          label: "Remove",
          icon: "mdi-delete",
          click: this.remove,
          condition: this.isDeleteTeamAllowed,
        },
      ],
    };
  },
  methods: {
    isDeleteTeamAllowed() {
      return this.$ability.can("manage", "Team");
    },
    isEditTeamAllowed() {
      return this.$ability.can("manage", "Team");
    },
    isCreateTeamAllowed() {
      return this.$ability.can("manage", "Team");
    },
    getRulesObject(item) {
      let rules = [];
      try {
        rules = JSON.parse(item.rules);
      } catch (e) {
        /**/
      }

      return rules;
    },
    viewDetails(item) {
      this.teamDetails = true;
      this.teamDetailsTeam = item;
    },
    async remove(item) {
      const confirmed = await this.$root.$confirm(
        "Question",
        "Do you really want to delete this team?"
      );
      if (!confirmed) return;

      try {
        await TeamService.remove(item._id);
        await this.$refs.datatable.fetch();
        this.$root.$snackbar.success("Successfully deleted team.");
      } catch (e) {
        /**/
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
