/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { Ability } from '@casl/ability';
import axios from 'axios';
import {
  AUTH_LOGOUT, AUTH_AD_LOGIN, AUTH_LOGINAS, AUTH_LOCAL_LOGIN, AUTH_OIDC, INIT_STATE, PERSIST_REDIRECT, CLEAR_REDIRECT,
} from '../actions/auth';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const state = {
  redirectTo: null,
  token: '',
  status: '',
  user: null,
  exp: 0,
  rules: [],
  ability: new Ability([]),
};

const getters = {
  redirectTo: state => state.redirectTo,
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  user: state => (state.token ? state.user : null),
  username: state => (state.token ? state.user.username.toLowerCase() : ''),
  team: state => (state.token ? state.user.team.toLowerCase() : ''),
  teamId: state => (state.token ? state.user.teamId : ''),
  name: state => (state.token ? state.user.name : ''),
  surname: state => (state.token ? state.user.surname : ''),
  isAdminTeam: state => (state.token ? state.user.isAdminTeam : false),
  rules: state => (state.token ? state.rules : []),
  token: state => state.token,
  ability: state => state.ability,
  expired: state => state.token !== '' && new Date().getTime() > (parseInt(state.exp, 10) * 1000),
};

const actions = {
  [INIT_STATE]: ({ commit }) => {
    const token = localStorage.getItem('token');
    if (token) {
      commit('UPDATE_STATE_BY_TOKEN', token);
    }
  },
  [AUTH_AD_LOGIN]: async ({ commit }, user) => {
    const resp = await axios({ url: '/api/v1/adlogin', data: user, method: 'POST' });
    commit('UPDATE_STATE_BY_TOKEN', resp.data.jwt);
  },
  [AUTH_LOCAL_LOGIN]: async ({ commit }, user) => {
    const resp = await axios({ url: '/api/v1/localLogin', data: user, method: 'POST' });
    commit('UPDATE_STATE_BY_TOKEN', resp.data.jwt);
  },
  [AUTH_LOGINAS]: async ({ commit }, data) => {
    const resp = await axios({ url: '/api/v1/users/loginas', data, method: 'POST' });
    commit('UPDATE_STATE_BY_TOKEN', resp.data.jwt);
  },
  [AUTH_OIDC]: async ({ commit }) => {
    try {
      const resp = await axios({ url: '/oidc/token' });
      commit('UPDATE_STATE_BY_TOKEN', resp.data.jwt);
    } catch (e) {
      commit('AUTH_ERROR', e);
    }
  },
  [AUTH_LOGOUT]: async ({ commit }) => {
    commit('AUTH_LOGOUT');
  },
  [PERSIST_REDIRECT]: ({ commit }, data) => {
    commit('PERSIST_REDIRECT', data.redirectTo);
  },
  [CLEAR_REDIRECT]: ({ commit }, data) => {
    commit('CLEAR_REDIRECT', data);
  },
};

const mutations = {
  UPDATE_STATE_BY_TOKEN: (state, token) => {
    // decode JWT
    const payload = parseJwt(token);
    if (!payload) {
      state.status = 'error';
      return;
    }

    // check exp
    if (new Date().getTime() > (payload.exp * 1000)) {
      state.status = 'expired';
      return;
    }

    // check for redirect
    const redirectTo = localStorage.getItem('redirectTo');

    // update state
    state.token = token;
    state.redirectTo = redirectTo;
    state.status = 'success';
    state.user = payload.user;
    state.rules = payload.rules;
    state.exp = payload.exp;

    // update abilities
    try {
      state.ability.update(state.rules);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('update CASL ability failed: ', e.message);
    }

    // persist in session storage
    localStorage.setItem('token', token);
  },
  AUTH_ERROR: (state) => {
    state.token = '';
    state.user = null;
    state.rules = [];
    state.status = 'error';
    state.exp = 0;
  },
  AUTH_LOGOUT: (state) => {
    state.token = '';
    state.user = null;
    state.rules = [];
    state.status = '';
    state.exp = 0;

    // update abilities
    state.ability = new Ability([]);

    // persist in session storage
    localStorage.removeItem('token');
  },
  PERSIST_REDIRECT: (state, redirectTo) => {
    state.redirectTo = redirectTo;
    localStorage.setItem('redirectTo', redirectTo);
  },
  CLEAR_REDIRECT: (state) => {
    state.redirectTo = '';
    localStorage.removeItem('redirectTo');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
