import axios from 'axios';

const basePath = '/api/v1/users';

const get = async (columns = []) => {
  const res = await axios.get(`${basePath}${columns.length ? `?columns=${columns.join()}` : ''}`);
  return res.data.data;
};
const getById = async (id) => {
  const res = await axios.get(`${basePath}/${id}`);
  return res.data.user;
};
const update = (id, data) => axios.patch(`${basePath}/${id}`, data);
const create = data => axios.post(basePath, data);
const remove = id => axios.delete(`${basePath}/${id}`);

export {
  get,
  getById,
  create,
  update,
  remove,
};
