<template>
  <my-text-field
    v-model="inputVal"
    validation-rule="username"
    label="E-Mail"
    :required="required"
    :disabled="disabled"
    autocomplete="email"
    :autofocus="true"
  />
</template>

<script>
import MyTextField from '@/components/Generic/MyTextField';

export default {
  components: {
    MyTextField,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
