import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
      themes: {
        light: {
          primary: colors.orange.darken1, // #E53935
          secondary: colors.grey.lighten4, // #FFCDD2
          accent: colors.orange.base, // #3F51B5
        },
      },
    },
  })