<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10">
        <span class="display-1">Parcels</span>

        <DataTable
          ref="datatable"
          :headers="headers"
          api-base-path="/api/v1/wfs/parcels"
          initial-sort-by="createdAt"
          :initial-descending="true"
          :initial-rows-per-page="15"
        >
          <template slot="items" slot-scope="props">
            <tbody>
              <tr v-for="item in props.items" :key="item._id">
                <td class="hidden-sm-and-down">{{ item.landkreis }}</td>
                <td>{{ item.gemarkung }} ({{ item.gemarkungId }})</td>
                <td>{{ item.flurnummer }}</td>
                <td>{{ item.geodatenbayern.lagebezeichnung }}</td>
                <td>{{ item.amtlicheFlaeche }}m² ({{ item.berechneteFlaeche }}m²)</td>
                <td class="hidden-sm-and-down"><time-stamp :ts="item.createdAt" /></td>
                <td class="table-actions">
                  <TableActions
                    :item="item"
                    :info-button-fn="viewDetails"
                    :action-button-items="actionButtonItems"
                  />
                </td>
              </tr>
            </tbody>
          </template>

          <!-- datatable buttons -->
          <template v-slot:buttons>
            <v-btn :disabled="true" @click="$router.push('/wfs/parcels/create')">
              <v-icon left> mdi-plus-circle-outline </v-icon>CREATE
            </v-btn>

             <v-btn :disabled="false" @click="migrate()">
              <v-icon left> mdi-plus-circle-outline </v-icon>MIGRATE DATA
            </v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>

    <!-- parcel details dialog -->
    <ParcelDetailsDialog v-model="itemDetails" :item="itemDetailsItem" />
  </v-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import * as ParcelService from "@/service/Parcels";
import DataTable from "@/components/Generic/DataTable";
import TableActions from "@/components/Generic/TableActions";
import ParcelDetailsDialog from "@/components/ParcelDetailsDialog";
import TimeStamp from "@/components/TimeStamp";
import { initFileDownload } from '@/lib/Utils';



export default {
  name: "ParcelsRetrieve",
  components: {
    DataTable,
    TableActions,
    TimeStamp,
    ParcelDetailsDialog,
  },
  data() {
    return {
      itemDetails: false,
      itemDetailsItem: {},
      headers: [
        {
          value: "landkreis",
          text: "Landkreis",
          sortable: true,
          align: "start hidden-sm-and-down",
        },
        {
          value: "gemarkung",
          text: "Gemarkung (Gemarkung ID)",
          sortable: true,
          align: "start",
        },
        {
          value: "flurnummer",
          text: "Flurnummer",
          sortable: true,
          align: "start",
        },
        {
          value: "geodatenbayern.lagebezeichnung",
          text: "Lagebezeichnung",
          sortable: true,
          align: "start",
        },
        {
          value: "amtlicheFlaeche",
          text: "Fläche",
          sortable: true,
          align: "start",
        },
        {
          value: "createdAt",
          text: "created at",
          sortable: true,
          align: "start hidden-sm-and-down",
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false,
          align: "end",
        },
      ],
      actionButtonItems: [
        {
          label: "View on map",
          icon: "mdi-map-search",
          click: (item) =>
            this.$router.push({
              name: "ParcelViewById",
              params: { parcelId: item._id },
            }),
          condition: () => true,
        },
        {
          label: "GeoJSON",
          icon: "mdi-download",
          click: (item) => this.downloadGeoJson(item, "json"),
          condition: () => true,
        },
        {
          label: "Edit meta data",
          icon: "mdi-pencil",
          click: (item) =>
            this.$router.push({
              name: "ParcelEdit",
              params: { editId: item._id },
            }),
          condition: () => true,
        },/*
        {
          label: "Remove",
          icon: "mdi-delete",
          click: this.remove,
          condition: () => true,
        },*/
      ],
    };
  },
  methods: {
    async downloadGeoJson(item) {
      const geojson = await ParcelService.downloadGeoJsonById(item._id);
      initFileDownload(JSON.stringify(JSON.parse(geojson), null, 2), 'text/json', `${item.landkreis}_${item.gemarkung}_${item.flurnummer}.json`);
    },
    async viewDetails(item) {
      this.itemDetailsItem = await ParcelService.getById(item._id);
      this.itemDetails = true;
    },
    async importjson() {
      await ParcelService.importjson();
      await this.$refs.datatable.fetch();
    },
    async migrate() {
      await ParcelService.migrate();
      await this.$refs.datatable.fetch();
    },
    async remove(item) {
      const confirmed = await this.$root.$confirm(
        "Question",
        "Do you really want to delete this parcel?"
      );
      if (!confirmed) return;

      try {
        await ParcelService.remove(item._id);
        await this.$refs.datatable.fetch();
        this.$root.$snackbar.success("Successfully deleted parcel.");
      } catch (e) {
        /**/
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
