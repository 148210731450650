var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("filter"),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","clearable":"","label":"Search"},on:{"input":_vm.debounceSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),_vm._t("buttons")],2),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right'
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" NO DATA HERE! ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" NO RESULTS! ")]},proxy:true},{key:"body",fn:function(ref){
    var items = ref.items;
return [_vm._t("items",null,{"items":items})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }