<template>
  <v-dialog :dark="dark" v-model="show" max-width="600px" scrollable>
    <v-card :dark="dark">
      <v-card-title class="headline" :dark="dark">
        {{ title }}    <v-btn @click.stop="show = false" icon right style="position: absolute; right: 12px;"><v-icon>mdi-close</v-icon></v-btn>

      </v-card-title>
      <v-card-text :dark="dark">
        <slot name="content" dark />
      </v-card-text>
      <v-card-actions :dark="dark">
        <slot name="buttons" />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Details",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
