<template>
  <v-dialog
    v-model="active"
    max-width="400px"
    min-width="150px"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ content }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.native="cancel"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          text
          @click.native="confirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * call it globally via: this.$root.$confirm('Question', 'Are you sure?')
 * .then(confirmed => {
 *   if (confirmed){
 *       // confirmed
 *   }  else {
 *       // canceled
 *   }
 * });
 */

export default {
  name: 'ConfirmDialog',
  data: () => ({
    active: false,
    title: 'ARE YOU SURE?',
    content: 'Are you sure?',
    confirmText: 'YES',
    cancelText: 'NO',
    resolve: null,
    reject: null,
  }),
  methods: {
    open(title, message) {
      this.title = title;
      this.content = message;
      this.active = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.active = false;
    },
    cancel() {
      this.resolve(false);
      this.active = false;
    },
  },
};
</script>
