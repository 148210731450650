<template>
  <div>
    <v-container
      fill-height
      fluid
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          lg8
          md10
          sm10
          xl6
          xs12
        >
          <span
            v-show="!editId"
            class="display-1"
          >Create New User</span>
          <span
            v-show="editId"
            class="display-1"
          >Edit User</span>

          <v-card class="elevation-1">
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <v-form>
                <v-card-text>
                  <v-container>
                    <v-layout row>
                      <h3>
                        USER
                        <span class="subheader" />
                      </h3>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-name-field
                          v-model.trim="form.name"
                          :required="true"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-surname-field
                          v-model.trim="form.surname"
                          :required="true"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-username-field
                          v-model.trim="form.username"
                          :required="true"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-password-field
                        v-model.trim="form.password"
                        validation-rule="password"
                        label="Password"
                        :required="!editId"
                      />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-team-field
                          v-model="form.teamId"
                          :required="true"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="raised"
                    @click="clear"
                  >
                    RESET
                  </v-btn>
                  <v-btn
                    :disabled="invalid"
                    color="raised primary"
                    @click="submit"
                  >
                    SAVE
                  </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import {
  ValidationObserver,
} from 'vee-validate';
import * as UserService from '@/service/Users';
import * as TeamService from '@/service/Teams';
import MyUsernameField from '@/components/MyFields/MyUsernameField';
import MySurnameField from '@/components/MyFields/MySurnameField';
import MyNameField from '@/components/MyFields/MyNameField';

import MyPasswordField from '@/components/Generic/MyPasswordField';
import MyTeamField from '@/components/MyFields/MyTeamField';

export default {
  name: 'UserCreate',
  components: {
    MyUsernameField,
    MyTeamField,
    MyPasswordField,
    ValidationObserver,
    MyNameField,
    MySurnameField,
  },
  props: {
    editId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      teams: [],
      form: this.getInitialFormValues(),
    };
  },
  created() {
    this.fetchTeams();
    if (this.editId) {
      this.fetchById(this.editId);
    }
  },
  mounted() {
    this.validateAll();
  },
  methods: {
    validateAll() {
      this.$refs.observer.reset();
      this.$refs.observer.validate();
    },
    async fetchById(id) {
      try {
        const user = await UserService.getById(id);
        this.form.username = user.username;
        this.form.name = user.name;
        this.form.surname = user.surname;
        this.form.teamId = user.team ? user.team._id : null;
        this.form.password = '';
      } catch (e) {
        this.$router.go(-1);
      }
    },
    getInitialFormValues() {
      return {
        username: '',
        password: '',
        teamId: '',
        name: '',
        surname: '',
      };
    },
    async fetchTeams() {
      this.teams = await TeamService.get();
      // eslint-disable-next-line no-nested-ternary
      this.teams.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

      return true;
    },
    async submit() {
      const data = this.form;
      try {
        if (this.editId) {
          await UserService.update(this.editId, data);
          await UserService.getById(this.editId);
          this.$root.$snackbar.success(`Successfully edited user "${this.form.username}"`);
        } else {
          await UserService.create(data);
          this.$root.$snackbar.success(
            `Successfully created user "${this.form.username}"`,
          );
        }
      } catch (e) { /**/ }

      return true;
    },
    clear() {
      if (this.editId) {
        this.$router.go();
      } else {
        this.form = this.getInitialFormValues();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 1rem;
}
</style>