/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
const validator = require('validator');

const rules = {
  // generic
  mongoId: {
    hint: "A MongoDB ObjectID, e.g. '5be93461ec581b005cb30e67'",
    rules: [
      [
        (value) => validator.isMongoId(value),
        'Invalid MongoDB ObjectID!',
      ],
    ],
  },
  descending: {
    hint: 'Boolean, whether the sort order should be descending or not',
    rules: [
      [
        (value) => validator.isBoolean(value),
        'Invalid sort order direction!',
      ],
    ],
  },
  rowsPerPage: {
    hint: 'The number of items to show per page (1-100)',
    rules: [
      [
        (value) => validator.isInt(value, { min: 1, max: 1000 }),
        'Invalid number for number of items per page!',
      ],
    ],
  },
  page: {
    hint: 'The number of the current page (min: 1)',
    rules: [
      [
        (value) => validator.isInt(value, { min: 1 }),
        'Invalid page number!',
      ],
    ],
  },
  search: {
    hint: 'The search value to filter items (length: 0 - 128)',
    rules: [
      [
        (value) => validator.isByteLength(value, { min: 0, max: 128 }),
        'Invalid search filter!',
      ],
    ],
  },
  allowedTeams: {
    hint: 'A valid list of teams (ids)',
    rules: [
      [
        (value) => Array.isArray(value),
        'Invalid team _ids array provided!',
      ],
      [
        (value) => value.every((teamId) => validator.isMongoId(teamId)),
        'Invalid team _ids provided!',
      ],
    ],
  },
  // Land
  landColumns: {
    hint: 'The land columns you want to retrieve',
    rules: [
      [
        (value) => value.split(',').map((column) => column.trim()).every((column) => [
          '_id',
          'createdBy',
          'csr',
          'crt',
          'chainedCrt',
          'key',
          'cname',
          'sans',
          'days',
          'sigAlgo',
          'subject',
          'issuer',
          'validFrom',
          'validTo',
          'serialNumber',
          'revoked',
          'certificateAuthority._id',
          'certificateAuthority.name',
          'team._id',
          'team.name',
          'createdAt',
          'updatedAt',
        ].includes(column)),
        'Invalid column name(s)!',
      ],
    ],
  },
  // Team
  teamName: {
    hint: 'The name of the team (min: 3, max: 256)',
    rules: [
      [
        (value) => /^\w[\w\s.()-]{2,128}$/.test(value),
        'Invalid team name format!',
      ],
    ],
  },
  teamSortBy: {
    hint: 'Sort items by this field',
    rules: [
      [
        (value) => validator.isByteLength(value, { min: 0, max: 64 }),
        'Invalid sortBy parameter!',
      ],
      [
        (value) => value === '' || value.split(',').map((column) => column.trim()).every((column) => ['_id', 'numOfValidJwts', 'notifyOnEnvs', 'notifyBeforeExpiration', 'name', 'emails', 'rules', 'createdBy', 'createdAt', 'updatedAt'].includes(column)),
        'Invalid sortBy column name!',
      ],
    ],
  },
  teamColumns: {
    hint: 'The Team columns you want to retrieve (e.g. _id,name,emails,rules,notifyBeforeExpiration,notifyOnEnvs,createdBy,createdAt,updatedAt)',
    rules: [
      [
        (value) => value.split(',').map((column) => column.trim()).every((column) => ['_id', 'numOfValidJwts', 'notifyOnEnvs', 'notifyBeforeExpiration', 'name', 'emails', 'rules', 'createdBy', 'createdAt', 'updatedAt'].includes(column)),
        'Invalid column name(s)!',
      ],
    ],
  },
  // User
  username: {
    hint: 'Your E-Mail',
    rules: [
      [
        (value) => validator.isEmail(value),
        'Invalid E-Mail format!',
      ],
    ],
  },
  name: {
    hint: 'Your name',
    rules: [
      [
        (value) => /^.{3,128}$/.test(value),
        'Invalid name format!',
      ],
    ],
  },
  surname: {
    hint: 'Your E-Mail',
    rules: [
      [
        (value) => /^.{3,128}$/.test(value),
        'Invalid surname format!',
      ],
    ],
  },
  password: {
    hint: 'Your password',
    rules: [
      [
        (value) => /^.{3,128}$/.test(value),
        'Invalid password!',
      ],
    ],
  },
  userSortBy: {
    hint: 'Sort items by this field',
    rules: [
      [
        (value) => validator.isByteLength(value, { min: 0, max: 64 }),
        'Invalid sortBy parameter!',
      ],
      [
        (value) => value === '' || value.split(',').map((column) => column.trim()).every((column) => ['_id', 'name', 'surname', 'username', 'lastLogin', 'team._id', 'team.name', 'team.rules', 'createdBy', 'createdAt', 'updatedAt'].includes(column)),
        'Invalid sortBy column name!',
      ],
    ],
  },
  userColumns: {
    hint: 'The User columns you want to retrieve (e.g. _id,username,name,surname,lastLogin,team._id,team.name,team.rules,createdBy,createdAt,updatedAt)',
    rules: [
      [
        (value) => value.split(',').map((column) => column.trim()).every((column) => ['_id', 'name', 'surname', 'username', 'lastLogin', 'team._id', 'team.name', 'team.rules', 'createdBy', 'createdAt', 'updatedAt'].includes(column)),
        'Invalid column name(s)!',
      ],
    ],
  },
  zaehler: {
    hint: 'Zähler (ownership)',
    rules: [
      [
        (value) => parseInt(value) >= 1 && parseInt(value) <= 100,
        'Invalid "Zähler" number"!',
      ],
    ],
  },
  nenner: {
    hint: 'Nenner (ownership)',
    rules: [
      [
        (value) => parseInt(value) >= 1 && parseInt(value) <= 100,
        'Invalid "Nenner" number"!',
      ],
    ],
  },
  besitzer: {
    hint: 'Owner of this parcel',
    rules: [
      [
        (value) => Array.isArray(value),
        'Invalid besitzer array provided!',
      ],
      [
        (value) => value.every((besitzer) => validator.isMongoId(besitzer.user._id)),
        'Invalid besitzer.user._id provided!',
      ],
      [
        (value) => value.every((besitzer) => parseInt(besitzer.zaehler) >= 1 && parseInt(besitzer.zaehler) <= 100),
        'Invalid besitzer.zaehler provided!',
      ],
      [
        (value) => value.every((besitzer) => parseInt(besitzer.nenner) >= 1 && parseInt(besitzer.nenner) <= 100),
        'Invalid besitzer.nenner provided!',
      ],
    ],
  },
  besitzerIds: {
    hint: 'Ids of besitzer',
    rules: [
      [
        (value) => Array.isArray(value.split(',')),
        'Invalid besitzer array provided!',
      ],
      [
        (value) => value.split(',').every((besitzerId) => validator.isMongoId(besitzerId)),
        'Invalid besitzerId!',
      ]
    ],
  },
};

module.exports = rules;
