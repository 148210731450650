<template>
  <div>
    <v-container
      fill-height
      fluid
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          lg8
          md10
          sm10
          xl6
          xs12
        >
          <span
            v-show="!editId"
            class="display-1"
          >Create New Team</span>
          <span
            v-show="editId"
            class="display-1"
          >Edit Team</span>

          <v-card class="elevation-1">
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <v-form>
                <v-card-text>
                  <v-container>
                    <v-layout row>
                      <h3>
                        TEAM
                        <span class="subheader" />
                      </h3>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <my-teamname-field
                          v-model="form.name"
                          :required="true"
                        />
                      </v-flex>
                    </v-layout>


                    
                    
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="raised"
                    @click="clear"
                  >
                    RESET
                  </v-btn>
                  <v-btn
                    :disabled="invalid"
                    color="raised primary"
                    @click="submit"
                  >
                    SAVE
                  </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import {
  ValidationObserver,
} from 'vee-validate';
import * as TeamService from '@/service/Teams';
import MyTeamnameField from '@/components/MyFields/MyTeamnameField';

export default {
  name: 'TeamCreate',
  components: {
    MyTeamnameField,
    ValidationObserver,
  },
  props: {
    editId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: this.getInitialFormValues(),
    };
  },
  created() {
    if (this.editId) {
      this.fetchById(this.editId);
    }
  },
  mounted() {
    this.validateAll();
  },
  methods: {
    isEdit() {
      return !!this.editId;
    },
    validateAll() {
      this.$refs.observer.reset();
      this.$refs.observer.validate();
    },
    async fetchById(id) {
      try {
        const team = await TeamService.getById(id);
        this.form = {
          name: team.name,
        }
      } catch (e) {
        this.$router.go(-1);
      }
    },
    getInitialFormValues() {
      return {
        name: '',
      };
    },
    async submit() {
      const data = this.form;
      try {
        if (this.editId) {
          await TeamService.update(this.editId, data);
          await TeamService.getById(this.editId);
          this.$root.$snackbar.success(`Successfully edited team "${this.form.name}"`);
        } else {
          await TeamService.create(data);
          this.$root.$snackbar.success(
            `Successfully created user "${this.form.name}"`,
          );
        }
      } catch (e) { /**/ }

      return true;
    },
    clear() {
      if (this.editId) {
        this.$router.go();
      } else {
        this.form = this.getInitialFormValues();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 1rem;
}
</style>