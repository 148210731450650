<template>
  <span>
    <template v-if="ts">
      <span :title="getFormattedTs(true)">{{ getFormattedTs() }}</span>
    </template>
    <template v-else>
      <span title="-">
        -
      </span>
    </template>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimeStamp',
  props: {
    ts: {
      type: String,
      required: true,
    },
  },
  methods: {
    getFormattedTs(exact = false) {
      return moment(new Date(this.ts).getTime()).format(exact ? 'DD.MM.YYYY, HH:mm:ss' : 'DD.MM.YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
