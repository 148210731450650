<template>
  <DetailsDialog
    v-model="show"
    :title="title"
  >
    <template v-slot:content>
      <v-tabs>
        <v-tab key="meta">
          META
        </v-tab>
        <v-tab-item key="meta">
          <h3>
            META DATA
            <span class="subheader" />
          </h3>
          <pre>{{ getMetaDetails() }}</pre>
        </v-tab-item>
      </v-tabs>
    </template>
  </DetailsDialog>
</template>

<script>
import DetailsDialog from '@/components/Generic/DetailsDialog';

export default {
  components: {
    DetailsDialog,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: 'User Details',
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    user() {
      return Object.keys(this.item).length > 0 ? this.item : null;
    },
  },
  methods: {
    getMetaDetails() {
      return this.user
        ? JSON.stringify(this.user, null, 2)
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.v-window-item {
  word-break: break-all;
  padding: 20px;
}
pre {
  white-space: pre-wrap;
}
</style>
