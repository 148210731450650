<template>
  <v-snackbar
    v-model="showSnackbar"
    multi-line="multi-line"
    :color="color"
    :timeout="timeout"
  >
    <v-icon>{{ icon }}</v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="showSnackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
/**
 * call it globally via: this.$root.$snackbar.show('error', 'Error Message', 3000);
 */

export default {
  name: 'SnackBar',
  data: () => ({
    position: 'bottom',
    timeout: -1,
    showSnackbar: false,
    message: '',
    color: '',
    icon: '',
  }),
  methods: {
    show(color, icon, message, timeout) {
      this.color = color;
      this.icon = icon;
      this.showSnackbar = true;
      this.message = message;
      this.timeout = timeout;
    },
    error(message) {
      this.show('red', 'mdi-alert-circle', message, 8000);
    },
    info(message) {
      this.show('yellow', 'mdi-information-variant', message, 6000);
    },
    warn(message) {
      this.show('orange', 'mdi-alert-outline', message, 6000);
    },
    success(message) {
      this.show('green', 'mdi-check', message, 6000);
    },
  },
};
</script>
