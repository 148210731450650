<template>
  <div>
    <v-toolbar text tile color="black" dark>

      <v-autocomplete v-model="select" :menu-props="{ auto: true, overflowY: false }" :search-input.sync="search"
        :filter="customFilter" spellcheck="false" hide-details class="mx-4" ref="search" hide-no-data dense rounded
        clearable filled append-outer-icon="mdi-tune-vertical" prepend-inner-icon="mdi-magnify" item-text="description"
        item-value="_id" @click:append-outer="showFilterSettings = !showFilterSettings" :items="items"
        :loading="loading"></v-autocomplete>
    </v-toolbar>

    <v-container fluid>
      <v-layout align-center>
        <v-flex lg12>

          <v-layout row wrap>
            <!-- <my-map-container ref="map" :highlight-feature-id="select" :geojsons="[]" @clicked="setSelected"
              @loaded="geojsonsLoaded" :feature-filter="featureFilter" /> -->
            <my-dynamic-map-container ref="map" :highlight-feature-id="select" :geojsons="[]" @clicked="setSelected"
              @loaded="geojsonsLoaded" :feature-filter="featureFilter" />
          </v-layout>
        </v-flex>


        <v-fab-transition>
          <v-btn v-show="!!detailsId" @click="showDetails" class="mx-4" fab fixed bottom right dark color="black">
            <v-icon dark> mdi-information-outline </v-icon>
          </v-btn>
        </v-fab-transition>
      </v-layout>
    </v-container>

    <!-- maps menue -->
    <v-bottom-sheet v-model="mapsMenue"></v-bottom-sheet>

    <!-- filter dialog -->
    <DetailsDialog :dark="true" v-model="showFilterSettings" title="Filter">
      <template v-slot:content>
        <v-layout row wrap>
          <v-col>
            <v-select v-model="featureFilter.besitzerIds" :items="besitzer" :clearable="true"
              label="Besitzer" multiple chips hint="Show only parcels owned by one of the selected users"
              persistent-hint item-text="description" item-value="_id"></v-select>
          </v-col>
        </v-layout>
      </template>
      <template v-slot:buttons>
        <v-btn color="raised primary" @click="updateFilter">
          SAVE
        </v-btn>
      </template>
    </DetailsDialog>

    <!-- parcel details dialog -->
    <ParcelDetailsDialog v-model="itemDetails" :item="itemDetailsItem" />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
//import MyMapContainer from "@/components/MyFields/MyMapContainer";
import * as ParcelService from "@/service/Parcels";
import * as UserService from "@/service/Users";
import ParcelDetailsDialog from "@/components/ParcelDetailsDialog";
import DetailsDialog from "@/components/Generic/DetailsDialog.vue";
import MyDynamicMapContainer from "@/components/MyFields/MyDynamicMapContainer.vue";

export default {
  name: "ParcelView",
  components: {
    //MyMapContainer,
    MyDynamicMapContainer,
    ParcelDetailsDialog,
    DetailsDialog,
  },
  props: {
    parcelId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showFilterSettings: false,
      mapsMenue: false,
      search: null,
      select: null,
      detailsId: null,
      items: [],
      itemDetails: false,
      itemDetailsItem: {},
      clickedCoordinate: [],
      loading: false,
      fab: false,
      firstPageLoad: true,
      featureFilter:
      {
        besitzerIds: [],
      },
      besitzer: [],
    };
  },
  async created() {
    const users = await UserService.get(['_id', 'name', 'surname']);
    const besitzer = users.map(user => {
      return {
        _id: user._id,
        description: `${user.surname}, ${user.name}`,
      };
    });
    this.besitzer = besitzer.sort((a, b) => ((a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0)));
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    select(id) {
      console.log('select (detailsId) is now: ', id);
      this.detailsId = id;
    },
  },
  methods: {
    geojsonsLoaded() {
      console.log('geojsons loaded');
      if (this.parcelId && this.firstPageLoad) {
        this.select = this.parcelId;
        this.firstPageLoad = false;
        console.log('set selected to: ', this.parcelId);
      }
    },
    customFilter() {
      // filtering is done on backend, so always return all items
      return true;
    },
    async querySelections(v) {
      this.loading = true;

      const results = await ParcelService.search(v, this.featureFilter);
      this.items = results;

      this.loading = false;
    },
    onChanged() {
      this.$refs.map.focus();
    },
    async showDetails() {
      this.itemDetailsItem = await ParcelService.getById(this.detailsId);
      this.itemDetails = true;
    },
    setClickedCoordinate(coordinate) {
      this.clickedCoordinate = coordinate;
    },
    setSelected(id) {
      this.detailsId = id;
    },
    updateFilter() {
      // TODO: ugly!!!
      const filter = {};
      Object.keys(this.featureFilter).forEach(v => {
        filter[v] = this.featureFilter[v];
      });
      this.featureFilter = filter;
      this.showFilterSettings = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 1rem;
}
</style>