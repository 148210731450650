<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-text-field
      v-model="inputVal"
      :error-messages="errors"
      :label="label"
      :hint="combinedHint"
      :append-icon="appendIcon"
      :type="type"
      :required="required && !disabled"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @click:append="clickAppend"
      :autofocus="autofocus"
    />
  </validation-provider>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { required } from "vee-validate/dist/rules";
import { setInteractionMode, ValidationProvider, extend } from "vee-validate";
import { getValidationRule, getHint } from "@/lib/ValidationRules";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    validationRule: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      required: false,
      default: "",
    },
    validationParams: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    clickAppend: {
      type: Function,
      required: false,
      default: () => null,
    },
    blur: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data: () => ({
    rules: "",
    combinedHint: "",
    v: true,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    validationParams: {
      handler() {
        this.updateValidation();
      },
      deep: true,
    },
    disabled: {
      handler() {
        this.updateValidation();
      },
    },
    required: {
      handler() {
        this.updateValidation();
      },
    },
  },
  created() {
    this.updateValidation();
  },
  methods: {
    updateValidation() {
      setInteractionMode("aggressive"); // TODO: set in parent form?! 'lazy'
      this.combinedHint = this.hint;
      if (this.validationRule) {
        getValidationRule(this.validationRule);
        this.combinedHint = `${this.hint} ${getHint(this.validationRule)}`;
      }
      if (this.required) {
        extend("required", {
          ...required,
          message: "{_field_} is required!",
        });
      }
      // additional validation params
      const params = this.validationParams.length
        ? `:${this.validationParams.join()}`
        : "";
      this.rules = `${this.required && !this.disabled ? "required|" : ""}${
        this.validationRule
      }${params}`;
    },
  },
};
</script>
