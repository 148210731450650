import Vue from 'vue';
import Router from 'vue-router';
import { AUTH_LOGOUT, PERSIST_REDIRECT } from '@/store/actions/auth';

import Index from '@/views/Index';

import LocalLogin from '@/views/Auth/LocalLogin';

import TeamRetrieve from '@/views/Team/Retrieve';
import TeamCreate from '@/views/Team/Create';

import UserRetrieve from '@/views/User/Retrieve';
import UserCreate from '@/views/User/Create';

import ParcelView from '@/views/Parcel/View';
import ParcelRetrieve from '@/views/Parcel/Retrieve';
import ParcelEdit from '@/views/Parcel/Edit';


import store from '../store';

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }

  // if not already logged in store dst route for redirect after login
  const redirectTo = to.fullPath;
  if (redirectTo != '/') {
    await store.dispatch(PERSIST_REDIRECT, { redirectTo });
  }

  next('/local-login');
};

const logout = async (to, from, next) => {
  await store.dispatch(AUTH_LOGOUT);

  next('/local-login');
};

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/wfs/parcels/retrieve',
      name: 'ParcelRetrieve',
      component: ParcelRetrieve,
      beforeEnter: ifAuthenticated,
    },
    {
      props: true,
      path: '/wfs/parcels/edit/:editId',
      name: 'ParcelEdit',
      component: ParcelEdit,
      beforeEnter: ifAuthenticated,
    },

    {
      path: '/wfs/parcels/view',
      name: 'ParcelView',
      component: ParcelView,
      beforeEnter: ifAuthenticated,
    },
    {
      props: true,
      required: false,
      path: '/wfs/parcels/view/:parcelId',
      name: 'ParcelViewById',
      component: ParcelView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: logout,
    },
    {
      path: '/local-login',
      name: 'LocalLogin',
      component: LocalLogin,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/teams/retrieve',
      name: 'TeamRetrieve',
      component: TeamRetrieve,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/teams/create',
      name: 'TeamCreate',
      component: TeamCreate,
      beforeEnter: ifAuthenticated,
    },
    {
      props: true,
      path: '/teams/edit/:editId',
      name: 'TeamEdit',
      component: TeamCreate,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/users/retrieve',
      name: 'UserRetrieve',
      component: UserRetrieve,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/users/create',
      name: 'UserCreate',
      component: UserCreate,
      beforeEnter: ifAuthenticated,
    },
    {
      props: true,
      path: '/users/edit/:editId',
      name: 'UserEdit',
      component: UserCreate,
      beforeEnter: ifAuthenticated,
    },
  ],
});
