import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import { INIT_STATE } from './store/actions/auth';
import store from './store';

Vue.config.productionTip = false

Vue.use(abilitiesPlugin, store.getters.ability);

// load store state form localStorage
store.dispatch(INIT_STATE);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
