<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
  >
    <v-text-field
      v-model="inputVal"
      type="password"
      :error-messages="errors"
      :label="label"
      :hint="hint"
      :required="required && !disabled"
      :disabled="disabled"
      autocomplete="current-password"
    />
  </validation-provider>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { required } from 'vee-validate/dist/rules';
import {
  setInteractionMode, ValidationProvider, extend,
} from 'vee-validate';
import { getValidationRule, getHint } from '@/lib/ValidationRules';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    validationRule: {
      type: String,
      required: true,
    },
    validationParams: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    rules: '',
    hint: '',
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    setInteractionMode('aggressive'); // TODO: set in parent form?! 'lazy'
    getValidationRule(this.validationRule);
    this.hint = getHint(this.validationRule);
    // eslint-disable-next-line max-len
    if (this.required) {
      extend('required', {
        ...required,
        message: '{_field_} is required!',
      });
    }
    // additional validation params
    const params = (this.validationParams.length) ? `:${this.validationParams.join()}` : '';
    this.rules = `${(this.required && !this.disabled) ? 'required|' : ''}${this.validationRule}${params}`;
  },
};
</script>
