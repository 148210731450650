<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10">
        <span class="display-1">Users</span>
        <DataTable
          ref="datatable"
          :headers="headers"
          api-base-path="/api/v1/users"
          initial-sort-by="username"
          :initial-descending="false"
          :initial-rows-per-page="10"
        >
          <template slot="items" slot-scope="props">
            <tbody>
              <tr v-for="item in props.items" :key="item._id">
                <td>{{ item.name }}</td>
                <td>{{ item.surname }}</td>
                <td>{{ item.username }}</td>
                <td class="hidden-sm-and-down">
                  <time-stamp :ts="item.lastLogin || ''" />
                </td>
                <td>
                  <template v-if="item.team">
                    {{ item.team.name }}
                  </template>
                  <template v-else>
                    <v-icon title="team does not exist anymore">
                      warning
                    </v-icon>
                  </template>
                </td>
                <td class="table-actions">
                  <TableActions
                    :item="item"
                    :info-button-fn="viewDetails"
                    :action-button-items="actionButtonItems"
                  />
                </td>
              </tr>
            </tbody>
          </template>

          <!-- datatable buttons -->
          <template v-slot:buttons>
            <v-btn to="/users/create">
              <v-icon left> mdi-plus-circle-outline </v-icon>CREATE</v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>

    <!-- user details dialog -->
    <UserDetailsDialog v-model="userDetails" :item="userDetailsUser" />
  </v-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { AUTH_LOGINAS } from "@/store/actions/auth";
import * as UserService from "@/service/Users";
import DataTable from "@/components/Generic/DataTable";
import TableActions from "@/components/Generic/TableActions";
import UserDetailsDialog from "@/components/UserDetailsDialog";
import TimeStamp from "@/components/TimeStamp";

export default {
  name: "UserRetrieve",
  components: {
    DataTable,
    TableActions,
    UserDetailsDialog,
    TimeStamp,
  },
  data() {
    return {
      userDetails: false,
      userDetailsUser: {},
      headers: [
      {
          value: "name",
          text: "Name",
          sortable: true,
          align: "start",
        },
        {
          value: "surname",
          text: "Surname",
          sortable: true,
          align: "start",
        },
        {
          value: "username",
          text: "Username",
          sortable: true,
          align: "start",
        },
        {
          value: "lastLogin",
          text: "Last login",
          sortable: true,
          align: "start hidden-sm-and-down",
        },
        {
          value: "team.name",
          text: "Team",
          sortable: true,
          align: "start",
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false,
          align: "end",
        },
      ],
      actionButtonItems: [
        {
          label: "Edit",
          icon: "mdi-pencil",
          click: (item) =>
            this.$router.push({
              name: "UserEdit",
              params: { editId: item._id },
            }),
          condition: this.isEditUserAllowed,
        },
        {
          label: "Login As",
          icon: "mdi-export",
          click: this.loginAs,
          condition: this.isLoginAsAllowed,
        },
        {
          label: "Remove",
          icon: "mdi-delete",
          click: this.remove,
          condition: this.isDeleteUserAllowed,
        },
      ],
    };
  },
  methods: {
    isDeleteUserAllowed() {
      return this.$ability.can("manage", "User");
    },
    isEditUserAllowed() {
      return this.$ability.can("manage", "User");
    },
    isCreateUserAllowed() {
      return this.$ability.can("manage", "User");
    },
    isLoginAsAllowed() {
      return this.$ability.can("manage", "User");
    },
    async viewDetails(item) {
      this.userDetailsUser = await UserService.getById(item._id);
      this.userDetails = true;
    },
    async remove(item) {
      const confirmed = await this.$root.$confirm(
        "Question",
        "Do you really want to delete this user?"
      );
      if (!confirmed) return;

      try {
        await UserService.remove(item._id);
        await this.$refs.datatable.fetch();
        this.$root.$snackbar.success("Successfully deleted user.");
      } catch (e) {
        /**/
      }
    },
    async loginAs(item) {
      const { username } = item;

      try {
        await this.$store.dispatch(AUTH_LOGINAS, { username });
        this.$root.$snackbar.success(
          `You are now logged in as user "${username}".`
        );
      } catch (e) {
        /**/
      }

      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
