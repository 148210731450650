<template>
  <div class="btn-group">
    <v-btn
      @click="infoButtonFn(item)"
    >
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          primary
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-cog</v-icon>
          <v-icon right>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list v-if="actionButtonItems.length > 0">
        <template v-for="(action, index) in actionButtonItems">
          <v-list-item
            v-if="action.condition(item)"
            :key="index"
            @click="action.click ? action.click(item) : ''"
          >
            <v-list-item-action class="justify-start">
              <v-icon>{{ action.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ action.label }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    infoButtonFn: {
      type: Function,
      required: false,
      default: null,
    },
    actionButtonItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-group {
  display: inline-flex;

  button {
    padding: 0 2px !important;
  }
}
</style>
