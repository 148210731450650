<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="5">
        <v-card :loading="loading">
          <v-card-title>MyLands</v-card-title>

          <v-card-subtitle>login</v-card-subtitle>

          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
              <v-card-text>
                <my-username-field
                  ref="username"
                  v-model.trim="login.username"
                  validation-rule="username"
                  :required="true"
                />
                <my-password-field
                  ref="password"
                  v-model.trim="login.password"
                  validation-rule="password"
                  label="Password"
                  :required="true"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="invalid || loading"
                >
                  login
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { AUTH_LOCAL_LOGIN, CLEAR_REDIRECT } from "@/store/actions/auth";
import { ValidationObserver } from "vee-validate";
import MyUsernameField from "@/components/MyFields/MyUsernameField";
import MyPasswordField from "@/components/Generic/MyPasswordField";

export default {
  name: "LocalLogin",
  components: {
    ValidationObserver,
    MyUsernameField,
    MyPasswordField,
  },
  data() {
    return {
      loading: false,
      login: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    focusPasswordField() {
      setTimeout(() => {
        this.$refs.password.$el.querySelector("input").focus();
      });
    },
    async doLogin() {
      const { username, password } = this.login;
      this.loading = true;

      try {
        await this.$store.dispatch(AUTH_LOCAL_LOGIN, { username, password });
        const redirectTo = this.$store.getters.redirectTo;
        if (redirectTo) {
          await this.$store.dispatch(CLEAR_REDIRECT);
          this.$router.push(redirectTo);
        } else {
          this.$router.push("/wfs/parcels/view");
        }
      } catch (e) {
        //this.login.username = "";
        this.login.password = "";
        this.focusPasswordField();
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      await this.doLogin();
    },
  },
};
</script>

