<template>
  <DetailsDialog :dark="false" v-model="show" :title="title">
    <template v-slot:content>
      <v-tabs >
        <v-tab key="basis" dark> FLURSTÜCK </v-tab>
        <v-tab key="meta"> META </v-tab>
        <v-tab key="raw"> RAW </v-tab>
        <v-tab-item key="basis" >
          <h3 >
            BASIS INFORMATIONEN
            <span class="subheader" />
          </h3>
          <v-divider ></v-divider>

          <v-list dense >
            <v-list-item >
              <v-list-item-content  dense class="details-key"
                >Landkreis</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.landkreis
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Gemarkung</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.gemarkung  
              }} ({{ item.gemarkungId }})</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Flurnummer</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.flurnummer
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Lagebezeichnung</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.geodatenbayern ? item.geodatenbayern.lagebezeichnung : '-'
              }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="details-key"
                >Fläche (amtlich)</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.amtlicheFlaeche
              }} ha</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="details-key"
                >Fläche (berechnet)</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                item.berechneteFlaeche
              }} ha</v-list-item-content>
            </v-list-item>
          </v-list>

          <h3>
            GRUNDSTEUERPORTAL
            <span class="subheader" />
          </h3>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Bodenschätzung</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ item.grundsteuerportal ? (item.grundsteuerportal.Bodenschätzung  || '-') : '-' }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Tatsächliche Nutzung</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ item.grundsteuerportal ? (item.grundsteuerportal['Tatsächliche Nutzung'] || '-') : '-' }}</v-list-item-content>
            </v-list-item>
<v-list-item>
              <v-list-item-content class="details-key"
                >Gesamtertragsmesszahl</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ item.grundsteuerportal ? (item.grundsteuerportal['Gesamtertragsmesszahl'] || '-') : '-' }}</v-list-item-content>
            </v-list-item>
            

          </v-list>
          
         
        </v-tab-item>
        <v-tab-item key="meta">
          <h3>
            BEZEICHNUNGEN
            <span class="subheader" />
          </h3>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Grundbuch</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ (item.meta && item.meta.bezeichnungGrundbuch) ? (item.meta.bezeichnungGrundbuch  || '-') : '-' }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="details-key"
                >Gebräuchlich</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ (item.meta && item.meta.bezeichnungGebraeuchlich) ? (item.meta.bezeichnungGebraeuchlich  || '-') : '-' }}</v-list-item-content>
            </v-list-item>          
          </v-list>

          <h3>
            MISC
            <span class="subheader" />
          </h3>
          <v-divider></v-divider>
          <v-list-item>
              <v-list-item-content class="details-key"
                >Pächter</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ (item.meta && item.meta.paechter) ? (item.meta.paechter  || '-') : '-' }}</v-list-item-content>
            </v-list-item>   
            <v-list-item>
              <v-list-item-content class="details-key"
                >Quelle</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ (item.meta && item.meta.quelle) ? (item.meta.quelle  || '-') : '-' }}</v-list-item-content>
            </v-list-item>   
            <v-list-item>
              <v-list-item-content class="details-key"
                >Herkunft</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ (item.meta && item.meta.herkunft) ? (item.meta.herkunft  || '-') : '-' }}</v-list-item-content>
            </v-list-item>   
            

            <h3>
            BESITZER
            <span class="subheader" />
          </h3>
          <v-divider></v-divider>
          <template v-if="item.besitzer">
            <v-list-item v-for="besitzer in item.besitzer" :key="`besitzer_${item._id}_${besitzer._id}`">
              <v-list-item-content class="details-key"
                >Besitzer</v-list-item-content
              >
              <v-list-item-content class="align-end">{{ besitzer.user.username ? (`${besitzer.user.username} (${besitzer.zaehler}/${besitzer.nenner})`  || '-') : '-' }}</v-list-item-content>
            </v-list-item>   
          </template>
          <template v-else>
            <v-list-item>-</v-list-item>
          </template>
          

        </v-tab-item>

        <v-tab-item key="raw">
          <h3>
            RAW DATA
            <span class="subheader" />
          </h3>
          <pre>{{ getRawDetails() }}</pre>
        </v-tab-item>
      </v-tabs>
    </template>
  </DetailsDialog>
</template>

<script>
import DetailsDialog from "@/components/Generic/DetailsDialog";

export default {
  components: {
    DetailsDialog,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: "Details",
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getRawDetails() {
      return JSON.stringify(this.item, null, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-window-item {
  word-break: break-all;
  margin: 20px;
}
pre {
  white-space: pre-wrap;
}
.details-key {
  font-weight: bold;
}
</style>
