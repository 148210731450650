<template>
  <div ref="map-root" style="width: 100%; height: 100%; position: fixed"></div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VectorImageLayer from "ol/layer/VectorImage";
import GeoJSON from "ol/format/GeoJSON";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import MultiPoint from 'ol/geom/MultiPoint';

import { circular } from "ol/geom/Polygon";

import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";

import { ScaleLine, Control, defaults as defaultControls } from "ol/control";

import { fromLonLat, transform, transformExtent } from "ol/proj";
import { bbox } from "ol/loadingstrategy";
//import { all } from "ol/loadingstrategy";

// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import "ol/ol.css";

import * as ParcelService from "@/service/Parcels";


class TraceMyLocationControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(onclick) {
    const button = document.createElement("button");
    button.innerHTML =
      '<i style="font-size: initial;" aria-hidden="true" class="v-icon notranslate mdi mdi-crosshairs-gps theme--dark"></i>';

    const element = document.createElement("div");
    element.className = "getmylocation ol-unselectable ol-control";
    element.appendChild(button);

    super({
      element: element,
      // target: options.target,
    });

    button.addEventListener("click", onclick);
  }
}

class TileLayerControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(cb_toggleView) {
    const button = document.createElement("button");
    button.innerHTML =
      '<i style="font-size: initial;" aria-hidden="true" class="v-icon notranslate mdi mdi-layers-outline theme--dark"></i>';

    const element = document.createElement("div");
    element.className = "tile-switch ol-unselectable ol-control";
    element.appendChild(button);

    super({
      element: element,
    });

    button.addEventListener("click", cb_toggleView);
  }
}

const googleMapsSatelliteLayer = new TileLayer({
  source: new XYZ({
    url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  }),
});

/*const satelliteLayer = new TileLayer({
  source: new XYZ({
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    maxZoom: 19,
  }),
});*/

const osmLayer = new TileLayer({
  source: new OSM(), // tiles are served by OpenStreetMap
  opaque: false,
  opacity: 1,
});

export default {
  name: "MyDynamicMapContainer",
  components: {},
  props: {
    highlightFeatureId: String,
    featureFilter: Object,
    showFeaturesFunction: Function,
  },
  data: () => ({
    olMap: null,
    mapTile: googleMapsSatelliteLayer,
    zoom: null,
    parcelsLayer: null,
    traceLocation: false,
    locationLayer: null,
    geolocationWatcher: null,
    combinedView: true,
    clickedCoordinate: [],
    parcelsVectorSource: null,
    totalFeaturesAvailable: null,
    featuresFetched: 0,
  }),
  mounted() {

    const token = this.$store.getters.token;

    this.parcelsVectorSource = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection) => {
        if (this.totalFeaturesAvailable == this.featuresFetched) {
          return;
        }
        const zoom = this.olMap.getView().getZoom();
        //this.olMap.getInteractions().forEach(x => x.setActive(false));
        this.olMap.getTargetElement().classList.add("spinner"); // show spinner
        console.log("loader...");
        const lonLatExtent = transformExtent(extent, "EPSG:3857", "EPSG:4326");
        const proj = projection.getCode();
        const url = `/api/v1/wfs/parcels/geojson?strategy=bbox&srsname=${proj}&zoom=${zoom}&resolution=${resolution}&bbox=${lonLatExtent.join(
          ","
        )},${proj}`;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        const onError = (e) => {
          this.parcelsVectorSource.removeLoadedExtent(extent);
          console.error(`ERROR: ${e.message}`);
        };
        xhr.onerror = onError;
        xhr.onload = () => {
          if (xhr.status == 200) {
            const res = JSON.parse(xhr.responseText);
            const total = res.total;
            this.totalFeaturesAvailable = total;

            if (!res.geojsons || !res.geojsons.features) {
              this.olMap.getTargetElement().classList.remove("spinner"); // remove spinner

              return;
            }

            const features = res.geojsons.features.map(geoJson => this.getFeatureByGeoJson(geoJson));

            if (features.length) {
              this.parcelsVectorSource.addFeatures(features);
              //this.olMap.getView().fit(this.parcelsVectorSource.getExtent());
            }
            this.olMap.getTargetElement().classList.remove("spinner"); // remove spinner
            //this.olMap.getInteractions().forEach(x => x.setActive(true));

            this.featuresFetched = this.parcelsLayer.getSource().getFeatures().length;

            this.$emit("loaded", true);
          } else {
            this.olMap.getTargetElement().classList.remove("spinner"); // remove spinner

            onError(new Error(`WFS request failed with code: ${xhr.status}!`));
          }
        };
        xhr.send();
      },
      strategy: bbox,
    });

    this.parcelsLayer = new VectorImageLayer({
      backgground: "#ffffff",
      imageRatio: 2,
      //maxResolution: 15,
      source: this.parcelsVectorSource,
      //style: parcelsStyle,
      style: this.parcelStyle,
    });

    this.locationLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.olMap = new Map({
      controls: defaultControls().extend([
        new TraceMyLocationControl(this.traceMyLocationClicked),
        new TileLayerControl(this.toggleView),
        new ScaleLine({
          units: "metric",
        }),
      ]),
      // the map will be created using the 'map-root' ref
      target: this.$refs["map-root"],
      layers: [
        googleMapsSatelliteLayer,
        //getOsmLayer(0.3),
        this.parcelsLayer,
        this.locationLayer,
      ],

      // the map view will initially show the whole world
      view: new View({
        zoom: 19,
        center: [1104698.3729776083, 6489449.783419584],
        constrainResolution: true,
      }),
    });

    this.zoom = this.olMap.getView().getZoom();
    /*this.olMap.on("moveend", () => {
      const newZoom = this.olMap.getView().getZoom();
      if (this.zoom != newZoom) {
        //const extent = this.olMap.getView().calculateExtent(this.olMap.getSize());
        //const lonLatExtent = transformExtent(extent, 'EPSG:3857','EPSG:25832');
        console.log(`new zoom level: ${newZoom}, extent:`);
        this.zoom = newZoom;
      }
    });*/

    this.olMap.on("click", (event) => {
      this.clickedCoordinate = transform(
        event.coordinate,
        "EPSG:3857",
        "EPSG:4326"
      );
      console.log(`clicked coordinate: ${this.clickedCoordinate}`);

      // will return the first feature under the pointer
      const clickedId = this.olMap.forEachFeatureAtPixel(
        event.pixel,
        (feature) => {
          return feature.get("id");
        }
      );
      console.log(clickedId);

      this.focusFeatureId(clickedId);

      // emit a `select` event, either with a feature or without
      this.$emit("clicked", clickedId);
    });

    this.parcelsVectorSource.changed();
  },
  watch: {
    async highlightFeatureId(id) {
      if (!id) return;

      this.olMap.getTargetElement().classList.add("spinner"); // remove spinner

      const source = this.parcelsLayer.getSource();
      let feature = source
        .getFeatures()
        .find((feature) => feature.get("id") == id);

      if (!feature) {
        const geoJson = await ParcelService.getGeoJsonById(id);
        const feature = this.getFeatureByGeoJson(geoJson);
        this.parcelsVectorSource.addFeatures([feature]);
      }

      this.fitFeatureId(id);
      this.focusFeatureId(id);

      this.olMap.getTargetElement().classList.remove("spinner"); // remove spinner
      this.$emit("loaded", true);
    },
    featureFilter() {
      this.parcelsVectorSource.changed();
    },
  },
  methods: {
    parcelStyle: function (feature) {
      if (feature.get("focus") == true) {
        return [
          new Style({
            stroke: new Stroke({
              color: "rgba(255, 255, 0, 1.0)",
              width: 2,
            }),
            fill: new Fill({
              color: "rgba(255, 255, 0, 0.3)",
            }),
            text: new Text({
              font: "20px Arial",
              text: `${feature.get("desc")}`,
              placement: "area",
              fill: new Fill({
                color: "rgba(255, 255, 0, 1.0)",
              }),
            }),
          }),
          new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: 'rgba(255, 255, 0, 1.0)',
              }),
            }),
            geometry: function (feature) {
              const coordinates = feature.getGeometry().getCoordinates()[0][0];
              return new MultiPoint(coordinates);
            },
          }),
        ];
      }

      if (this.featureFilter.besitzerIds.length) {
        const featureBesitzerIds = feature.get('besitzerIds');
        const besitzerIds = this.featureFilter.besitzerIds;

        if (!featureBesitzerIds || !featureBesitzerIds.length) {
          return [];
        }

        if (featureBesitzerIds.some(besitzerId => besitzerIds.includes(besitzerId))) {
          return [new Style({
            stroke: new Stroke({
              color: "rgba(255, 255, 0, 1.0)",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(255, 255, 0, 0.0)",
            }),
            text: new Text({
              font: "15px Arial",
              text: `${feature.get("desc")}`,
              placement: "area",
              fill: new Fill({
                color: "rgba(255, 255, 0, 1.0)",
              }),
            }),
          })];
        } else {
          return [];
        }
      }

      // return default style
      return [
        new Style({
          stroke: new Stroke({
            color: "rgba(255, 255, 0, 1.0)",
            width: 1,
          }),
          fill: new Fill({
            color: "rgba(255, 255, 0, 0.0)",
          }),
          text: new Text({
            font: "15px Arial",
            text: `${feature.get("desc")}`,
            placement: "area",
            fill: new Fill({
              color: "rgba(255, 255, 0, 1.0)",
            }),
          }),
        })
      ];
    },
    getFeatureByGeoJson(geoJson) {
      const feature = new GeoJSON({
        featureProjection: "EPSG:3857",
      }).readFeature(geoJson);
      feature.setId(feature.values_.id);
      feature.setProperties({
        desc: feature.values_.desc,
        besitzerIds: feature.values_.besitzerIds,
      });

      return feature;
    },
    fitFeatureId(id) {
      console.log("fitFeatureId: ", id);
      const view = this.olMap.getView();
      const source = this.parcelsLayer.getSource();
      if (!id) {
        view.fit(source.getExtent());
        this.focusFeature(null);
      } else {
        const feature = source
          .getFeatures()
          .find((feature) => feature.get("id") == id);
        view.fit(feature.getGeometry().getExtent());
      }
    },
    focusFeatureId(id) {
      const source = this.parcelsLayer.getSource();

      source.getFeatures().forEach((feature) => {
        feature.set("focus", feature.get("id") == id ? true : false);
      });
    },
    enableWatchGeolocation() {
      let firstPosition = true;
      this.geolocationWatcher = navigator.geolocation.watchPosition(
        (pos) => {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          const accuracy = circular(coords, pos.coords.accuracy);
          const map = this.olMap;
          const source = this.locationLayer.getSource();
          source.clear(true);
          source.addFeatures([
            new Feature(
              accuracy.transform("EPSG:4326", map.getView().getProjection())
            ),
            new Feature(new Point(fromLonLat(coords))),
          ]);
          console.log(coords, pos.coords.accuracy);

          if (firstPosition) {
            map.getView().fit(source.getExtent(), {
              maxZoom: 18,
              duration: 500,
            });
            firstPosition = false;
          }
        },
        (error) => {
          // TODO
          console.error(error);
          this.disableWatchGeolocation();
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    },
    disableWatchGeolocation() {
      navigator.geolocation.clearWatch(this.geolocationWatcher);
      const source = this.locationLayer.getSource();
      source.clear(true);
    },
    toggleView() {
      if (this.mapTile == googleMapsSatelliteLayer) {
        this.olMap.setLayers([osmLayer, this.locationLayer, this.parcelsLayer]);
        this.mapTile = osmLayer;
      } else if (this.mapTile == osmLayer) {
        this.olMap.setLayers([
          googleMapsSatelliteLayer,
          this.locationLayer,
          this.parcelsLayer,
        ]);
        this.mapTile = googleMapsSatelliteLayer;
      }
    },
    traceMyLocationClicked() {
      if (!this.traceLocation) {
        this.enableWatchGeolocation();
        this.traceLocation = true;
      } else {
        this.disableWatchGeolocation();
        this.traceLocation = false;
      }
    },
  },
};
</script>

<style>
.getmylocation {
  top: 6em;
  left: 0.5em;
}

.ol-control button:hover,
.ol-control button:focus {
  background-color: rgba(0, 0, 0, 0.7);
}

.ol-control button {
  background-color: rgba(0, 0, 0, 0.4);
}

.tile-switch {
  bottom: 10em;
  left: 0.5em;
}

.ol-scale-line {
  bottom: 8em;
  left: 0.5em;
  background-color: rgba(0, 0, 0, 0.4);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: "";
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid rgba(180, 180, 180, 0.6);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}
</style>
