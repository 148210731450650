<template>
  <div>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex lg8 md10 sm10 xl6 xs12>
          <span class="display-1">Edit metadata of parcel</span>

          <v-card class="elevation-1">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form>
                <v-card-text>
                  <v-container>

                    <v-layout row>
                      <h3>
                        BEZEICHNUNGEN
                        <span class="subheader"></span>
                      </h3>
                    </v-layout>

                    <v-layout row wrap>
                      <v-col cols="12" sm="12" md="6">
                        <my-text-field v-model="item.meta.bezeichnungGrundbuch" validation-rule=""
                          label="Bezeichnung (laut Grundbuch)" :required="false" :disabled="false" />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <my-text-field v-model="item.meta.bezeichnungGebraeuchlich" validation-rule=""
                          label="Bezeichnung (gebräuchlich)" :required="false" :disabled="false" />
                      </v-col>
                    </v-layout>

                    <v-layout row>
                      <h3>
                        MISC
                        <span class="subheader"></span>
                      </h3>
                    </v-layout>
                    <v-layout row wrap>
                      <v-col cols="12" sm="12" md="6">
                        <my-text-field v-model="item.meta.paechter" validation-rule="" label="Pächter" :required="false"
                          :disabled="false" />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <my-text-field v-model="item.meta.herkunft" validation-rule="" label="Herkunft"
                          :required="false" :disabled="false" />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <my-text-field v-model="item.meta.quelle" validation-rule="" label="Quelle" :required="false"
                          :disabled="false" />
                      </v-col>
                    </v-layout>


                    <v-layout row>
                      <h3>
                        BESITZER
                        <span class="subheader"></span>
                      </h3>
                    </v-layout>
                    <v-layout row>
                      <v-col>
                        <my-owner-field v-model="item.besitzer"></my-owner-field>
                      </v-col>
                    </v-layout>


                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn :disabled="invalid" color="raised primary" @click="submit">
                    SAVE
                  </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { ValidationObserver } from "vee-validate";
import * as ParcelService from "@/service/Parcels";
// import * as TeamService from '@/service/Teams';
import MyOwnerField from "@/components/MyFields/MyOwnerField.vue";
import MyTextField from "@/components/Generic/MyTextField.vue";

export default {
  name: "LandCreate",
  components: {
    ValidationObserver,
    MyOwnerField,
    MyTextField,
  },
  props: {
    editId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      item: {
        besitzer: [],
        meta: {
          bezeichnungGrundbuch: '',
          bezeichnungGebraeuchlich: '',
          paechter: '',
          herkunft: '',
          quelle: '',
        }
      }
    };
  },
  created() {
    if (this.editId) {
      this.fetchById(this.editId);
    }
  },
  mounted() {
    this.validateAll();
  },
  methods: {
    async fetchById(id) {
      try {
        const item = await ParcelService.getById(id);
        this.item = {
          besitzer: item.besitzer || [],
          meta: item.meta,
        };
      } catch (e) {
        this.$router.go(-1);
      }
    },
    validateAll() {
      this.$refs.observer.reset();
      this.$refs.observer.validate();
    },
    async submit() {
      console.log(JSON.stringify(this.item, null, 2));

      const data = this.item;
      try {
        await ParcelService.updateMetadata(this.editId, data);
        this.$root.$snackbar.success(`Successfully edited parcel.`);
      } catch (e) {
        /**/
      }

    },

  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 1rem;
}
</style>