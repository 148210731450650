<template>
  <v-card flat>
    <v-card-title>
      <!-- select filter -->
      <slot name="filter" />

      <!-- search box -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        clearable
        label="Search"
        @input="debounceSearch"
      />
      <v-spacer />
      <v-spacer />

      <!-- button slot -->
      <slot name="buttons" />
    </v-card-title>
    <v-data-table
      mobile-breakpoint="0"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      }"
    >
      <template v-slot:no-data>
        NO DATA HERE!
      </template>
      <template v-slot:no-results>
        NO RESULTS!
      </template>
      <template v-slot:body="{ items }">
        <slot
          name="items"
          :items="items"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
  props: {
    apiBasePath: {
      type: String,
      required: true,
    },
    queryParams: {
      type: Object,
      required: false,
      default: () => { },
    },
    headers: {
      type: Array,
      required: true,
    },
    initialSortBy: {
      type: String,
      required: true,
    },
    initialRowsPerPage: {
      type: Number,
      required: true,
    },
    initialDescending: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    search: '',
    totalItems: 0,
    options: {},
    debounce: null,
    conditions: {},
  }),
  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true,
    },
    queryParams: {
      async handler() {
        await this.fetch();
      },
      deep: true,
    },
  },
  mounted() {
    this.options.rowsPerPage = this.initialRowsPerPage;
    this.options.sortBy[0] = this.initialSortBy;
    this.options.sortDesc[0] = this.initialDescending;

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }

    this.fetch();
  },
  methods: {
    debounceSearch(value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = value;
        this.options.page = 1;
        this.fetch();
      }, 600);
    },
    async filterItems() {
      await this.fetch();
    },
    async fetch() {
      this.loading = true;
      try {
        const {
          sortBy, sortDesc, page, itemsPerPage,
        } = this.options;

        const params = {
          search: this.search,
          sortBy: sortBy[0],
          descending: sortDesc[0],
          page,
          rowsPerPage: itemsPerPage,
          ...this.queryParams,
        };

        const response = await axios.get(this.apiBasePath, {
          params,
          paramsSerializer: p => qs.stringify(p),
        });

        this.items = response.data.data;
        this.totalItems = response.data.total;
      } catch (e) { /**/ }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
