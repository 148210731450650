const initFileDownload = (data, type, name) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = window.URL.createObjectURL(new Blob([data], { type }));
  link.download = name;
  link.target = '_blank';
  link.click();
};

const initTextFileDownload = (data, filename) => {
  initFileDownload(data, 'text/plain', filename);
};

export {
  initFileDownload,
  initTextFileDownload,
};
