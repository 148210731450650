import axios from 'axios';

const basePath = '/api/v1/wfs/parcels';

const get = async (columns = []) => {
  const res = await axios.get(`${basePath}${columns.length ? `?columns=${columns.join()}` : ''}`);
  return res.data.data;
};
const getById = async (id) => {
  const res = await axios.get(`${basePath}/${id}`);
  return res.data.parcel;
};
const downloadGeoJsonById = async (id) => {
  const res = await axios.get(`${basePath}/download/geojson/${id}`);
  return res.data.geojson;
}
const getGeoJsonById = async (id) => {
  const res = await axios.get(`${basePath}/geojson/${id}`);
  return res.data;
}
const updateMetadata = (id, data) => axios.patch(`${basePath}/metadata/${id}`, data);
const remove = id => axios.delete(`${basePath}/${id}`);

const importjson = () => axios.post(`${basePath}/importjson`);

const migrate = () => axios.post(`${basePath}/migrate`);

const findByCoordinate = async (coordinate) => {
  const res = await axios.get(`${basePath}/coordinate/${coordinate.join(',')}`);
  return res.data.parcel;
}

const search = async (val, filter = {}) => {
  let filterQuery = '';
  Object.keys(filter).forEach(v => {
    filterQuery += `&${v}=${filter[v]}`;
  });
  const res = await axios.get(`${basePath}?columns=_id,besitzer.user._id,landkreis,meta.paechter,meta.bezeichnungGrundbuch,meta.bezeichnungGebraeuchlich,geodatenbayern.lagebezeichnung,gemarkung,gemarkungId,flurnummer&sortBy=flurnummer&search=${val}&page=1&rowsPerPage=10${filterQuery}`);
  return res.data.data.map(r => {
    return {
      _id: r._id,
      description: `${(r.meta && r.meta.bezeichnungGebraeuchlich) ? '"' + r.meta.bezeichnungGebraeuchlich + '", ' : ''}#${r.flurnummer} (${r.gemarkung}) ${r.geodatenbayern.lagebezeichnung ? `, "${r.geodatenbayern.lagebezeichnung}"` : ''}`,
      //description: `${(r.meta && r.meta.bezeichnungGebraeuchlich) ? '"' + r.meta.bezeichnungGebraeuchlich + '" ' : ''}${r.landkreis}, ${r.gemarkung} (${r.gemarkungId}), #${r.flurnummer}${r.geodatenbayern.lagebezeichnung ? `, "${r.geodatenbayern.lagebezeichnung}"` : ''}`,
    };
  });
}

export {
  get,
  getById,
  findByCoordinate,
  updateMetadata,
  remove,
  importjson,
  search,
  migrate,
  getGeoJsonById,
  downloadGeoJsonById,
};
